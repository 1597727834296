<template>
<div class="recruit-box left clearfix">
  <Header></Header>
  <!--顶部banner开始-->
  <div class="aboutus-banner left" style="margin-bottom:20px;"> <img src="~images/banner/qiyezhaopin.jpg" width="100%" /> </div>

  <div class="contact-content width-bai left">
  <div class="contact-contents center" style="padding-top:30px;">

  <h1 style="margin-left:30px;">申请职位</h1>
  <div class="recruit-form">
                    <Form :model="formItem" :label-width="100" :rules="ruleValidate" ref="formItem" style="width:70%;margin-left:15%;">
                        <FormItem label="姓名" prop="name">
                            <Input v-model="formItem.name" placeholder="请输入真实姓名"></Input>
                        </FormItem>
                        <FormItem label="性别" prop="sex">
                            <RadioGroup v-model="formItem.sex">
                                <Radio label="先生" value="先生">先生</Radio>
                                <Radio label="女士" value="女士">女士</Radio>
                            </RadioGroup>
                        </FormItem>

                        <FormItem label="出生日期" prop="birthday">
                            <Row>
                                <Col span="11"> <DatePicker type="date" placeholder="请选择日期" v-model="formItem.birthday"></DatePicker> </Col>
                            </Row>
                        </FormItem>

                        <FormItem label="民族" prop="nation"> <Input v-model="formItem.nation" placeholder="请输入民族"></Input> </FormItem>
                        <FormItem label="籍贯" prop="native"> <Input v-model="formItem.native" placeholder="请输入籍贯"></Input> </FormItem>

                        <FormItem label="婚姻状况" prop="marriage">
                            <Select v-model="formItem.marriage">
                                <Option value="未婚">未婚</Option>
                                <Option value="已婚">已婚</Option>
                                <Option value="离异">离异</Option>
                            </Select>
                        </FormItem>

                        <FormItem label="政治面貌" prop="politics"> <Input v-model="formItem.politics" placeholder="请输入政治面貌"></Input> </FormItem>

                        <FormItem label="学历" prop="education">
                            <Select v-model="formItem.education">
                                <Option value="本科">本科</Option>
                                <Option value="大专">大专</Option>
                                <Option value="研究生">研究生</Option>
                                <Option value="博士生">博士生</Option>
                                <Option value="博士后">博士后</Option>
                                <Option value="硕士">硕士</Option>
                                <Option value="高中">高中</Option>
                                <Option value="初中">初中</Option>
                                <Option value="小学">小学</Option>
                            </Select>
                        </FormItem>

                        <FormItem label="专业" prop="major"> <Input v-model="formItem.major" placeholder="请输入专业"></Input> </FormItem>
                        <FormItem label="毕业院校" prop="school"> <Input v-model="formItem.school" placeholder="请输入毕业院校"></Input> </FormItem>
                        <FormItem label="联系电话" prop="tel"> <Input v-model="formItem.tel" placeholder="请输入联系电话"></Input> </FormItem>
                        <FormItem label="现住址" prop="address"> <Input v-model="formItem.address" placeholder="请输入现住址"></Input> </FormItem>
                        <FormItem label="期望工资" prop="wages"> <Input v-model="formItem.wages" placeholder="请输入期望工资"></Input> </FormItem>
                        <FormItem label="到岗时间" prop="date"> <Input v-model="formItem.date" placeholder="请输入可到岗时间"></Input> </FormItem>
                        <FormItem label="其他要求" prop="requirement">
                            <Input v-model="formItem.requirement" placeholder="请输入其他要求"></Input>
                        </FormItem>

                        <div class="form-title">教育经历</div>
                        
                        <div class="form-label">
                            <label>起止时间</label>
                            <label>学校名称</label>
                            <label>所学专业</label>
                            <label>取得学历</label>
                        </div>

                        <div class="form-label">
                            <input v-model="formItem.studytime1" placeholder="请输入起止时间">
                            <input v-model="formItem.studyschool1" placeholder="请输入学校名称">
                            <input v-model="formItem.studymajor1" placeholder="请输入所学专业">
                            <input v-model="formItem.studyeducation1" placeholder="请输入学历">
                        </div>

                        <div class="form-label">
                            <input v-model="formItem.studytime2" placeholder="请输入起止时间">
                            <input v-model="formItem.studyschool2" placeholder="请输入学校名称">
                            <input v-model="formItem.studymajor2" placeholder="请输入所学专业">
                            <input v-model="formItem.studyeducation2" placeholder="请输入学历">
                        </div>

                        <div class="form-label">
                            <input v-model="formItem.studytime3" placeholder="请输入起止时间">
                            <input v-model="formItem.studyschool3" placeholder="请输入学校名称">
                            <input v-model="formItem.studymajor3" placeholder="请输入所学专业">
                            <input v-model="formItem.studyeducation3" placeholder="请输入学历">
                        </div>

                        <div class="form-title">工作经历</div>
                        
                        <div class="form-label">
                            <label>起止时间</label>
                            <label>工作单位</label>
                            <label>职务</label>
                            <label>证明人</label>
                        </div>

                        <div class="form-label">
                            <input v-model="formItem.workdate1" placeholder="请输入起止时间">
                            <input v-model="formItem.workaddress1" placeholder="请输入工作单位">
                            <input v-model="formItem.workpost1" placeholder="请输入职务">
                            <input v-model="formItem.workname1" placeholder="请输入证明人">
                        </div>

                        <div class="form-label"> 
                            <input v-model="formItem.workdate2" placeholder="请输入起止时间">
                            <input v-model="formItem.workaddress2" placeholder="请输入工作单位">
                            <input v-model="formItem.workpost2" placeholder="请输入职务">
                            <input v-model="formItem.workname2" placeholder="请输入证明人">
                        </div>

                        <div class="form-label"> 
                            <input v-model="formItem.workdate3" placeholder="请输入起止时间">
                            <input v-model="formItem.workaddress3" placeholder="请输入工作单位">
                            <input v-model="formItem.workpost3" placeholder="请输入职务">
                            <input v-model="formItem.workname3" placeholder="请输入证明人">
                        </div>
                        
                        <FormItem label="自我评价" prop="evaluate" style="margin-top:50px;">
                           <Input v-model="formItem.evaluate" type="textarea" :autosize="{minRows: 5,maxRows: 10}" placeholder="请输入自我评价"></Input>
                        </FormItem>
                        <input type="hidden" v-model="formItem.id" />
                        
                    <div class="form-button" style="margin-bottom:30px;">
                       <FormItem> <Button type="primary" @click="handleSubmit('formItem')" style="width:120px;">提交申请</Button> </FormItem>
                    </div>
                    
                    </Form>
                </div>

                </div>
                </div>
  <div class="left clearfix width-bai"> <Footer></Footer> </div>
  <contact-call></contact-call>
</div>
</template>
<style>
    .ivu-form-item-content{margin-top:10px;}
    .ivu-form-item-error-tip{margin-left:0px;line-height:20px;}
    .ivu-form .ivu-form-item-label{padding-top:3px;font-weight:bold;margin-top:20px;}
    .recruit-box h1{font-size:15px;border-left:5px solid rgb(242,151,0);padding-left:10px;margin-bottom:40px;}
    .contact-text{width:100%;min-height:800px;margin-top:20px;}
    .contact-contents{min-height:900px;width:1000px;margin-top:10px;font-size:16px;box-shadow: 0px 2px 5px #cccccc;margin-top:50px;padding-bottom:50px;}
    .contact-content{min-height:900px;}
    .form-label input{width:23%;margin-left:1%;margin-right:1%;line-height:30px;border:1px solid #ddd;border-radius:5px;padding-left:5px;}
    .form-button{width:100%;height:60px;margin-top:30px;text-align:center;}
    .form-button Button{margin-top:50px;margin-bottom:50px;margin-right:20px;}
    .form-label{width:100%;line-height:40px;}
    .form-label label{width:23%;float:left;margin-left:1%;margin-right:1%;text-align:center;font-weight:bold;font-size:15px;}
    .form-title{width:100%;line-height:60px;font-weight:bold;font-size:15px;}
</style>
<script>
//import $ from 'jquery'
import ContactCall from '../index/ContactCall.vue'
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'

export default {
        data () {
            return {
                formItem: {
                    name:'',
                    sex:'先生',
                    birthday:'',
                    nation:'',
                    native:'',
                    marriage:'',
                    politics:'',
                    education:'',
                    major:'',
                    school:'',
                    tel:'',
                    address:'',
                    wages:'',
                    date:'',
                    requirement:'',
                    studytime1:'',
                    studytime2:'',
                    studytime3:'',
                    studyschool1:'',
                    studyschool2:'',
                    studyschool3:'',
                    studymajor1:'',
                    studymajor2:'',
                    studymajor3:'',
                    studyeducation1:'',
                    studyeducation2:'',
                    studyeducation3:'',
                    workdate1:'',
                    workdate2:'',
                    workdate3:'',
                    workaddress1:'',
                    workaddress2:'',
                    workaddress3:'',
                    workpost1:'',
                    workpost2:'',
                    workpost3:'',
                    workname1:'',
                    workname2:'',
                    workname3:'',
                    evaluate:'',
                    id:this.$route.query.recruit
                },
                ruleValidate: {
                    name: [
                        { required: true, message: '请填写姓名', trigger: 'blur' }
                    ],
                    sex: [
                        { required: true, message: '请选择性别', trigger: 'blur' }
                    ],
                    nation: [
                        { required: true, message: '请填写民族', trigger: 'blur' }
                    ],
                    native: [
                        { required: true, message: '请填写籍贯', trigger: 'blur' }
                    ],
                    
                    politics: [
                        { required: true, message: '请填写政治面貌', trigger: 'blur' }
                    ],
                   
                    major: [
                        { required: true, message: '请填写专业', trigger: 'blur' }
                    ],
                    school: [
                        { required: true, message: '请填写毕业院校', trigger: 'blur' }
                    ],
                    tel: [
                        { required: true, message: '请填写联系电话', trigger: 'blur' }
                    ],
                    address: [
                        { required: true, message: '请填写现住址', trigger: 'blur' }
                    ],
                    wages: [
                        { required: true, message: '请填写期望工资', trigger: 'blur' }
                    ],
                    date: [
                        { required: true, message: '请填写到岗时间', trigger: 'blur' }
                    ],
                    studytime: [
                        { required: true, message: '请至少填写一项学习经历', trigger: 'blur' }
                    ],
                    workdate: [
                        { required: true, message: '请至少填写一项工作经历', trigger: 'blur' }
                    ],
                    evaluate: [
                        { required: true, message: '请填写自我评价', trigger: 'blur' }
                    ]

                }

            }
            

        },
        components:{
            ContactCall,
            Header,
            Footer
        },

methods: {
handleSubmit (name) {
  this.$refs[name].validate((valid) => {
      console.log(valid);
      if (valid) {
          this.formItem.token=this.$store.state.token;
          var param=JSON.stringify(this.formItem);
          param = eval('(' + param + ')');
          //console.log(param.token);
          this.$axios({method:'post',url:window.configs.svcProt + "://" + window.configs.svcIpPort + '/submit_recruit.html',params:param}).then(res=>{
              if(res.data.result==true){
                  this.$Modal.success({
                      title: '温馨提示',
                      content: '申请成功，客服会尽快电话联系您，请耐心等待··',
                      onOk: () => {
                          location.href="/recruit"
                      },
                  });
              }
          });
          
      } 
  })
}
            
}

}
</script>

